
import { enableES5 } from 'immer';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from 'src/serviceWorker';

import { configureStore } from 'src/store';

import App from 'src/App';

enableES5();

const store = configureStore();


ReactDOM.render(
  <Provider store={store}>
    
      <App />
    
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
