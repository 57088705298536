export const countryList = [
  {name: 'Afghanistan', code: 'AF'},
  {name: 'Albania', code: 'AL'},
  {name: 'Algeria', code: 'DZ'},
  {name: 'American Samoa', code: 'AS'},
  {name: 'AndorrA', code: 'AD'},
  {name: 'Angola', code: 'AO'},
  {name: 'Anguilla', code: 'AI'},
  {name: 'Antarctica', code: 'AQ'},
  {name: 'Antigua and Barbuda', code: 'AG'},
  {name: 'Argentina', code: 'AR'},
  {name: 'Armenia', code: 'AM'},
  {name: 'Aruba', code: 'AW'},
  {name: 'Australia', code: 'AU'},
  {name: 'Austria', code: 'AT'},
  {name: 'Azerbaijan', code: 'AZ'},
  {name: 'Bahamas', code: 'BS'},
  {name: 'Bahrain', code: 'BH'},
  {name: 'Bangladesh', code: 'BD'},
  {name: 'Barbados', code: 'BB'},
  {name: 'Belarus', code: 'BY'},
  {name: 'Belgium', code: 'BE'},
  {name: 'Belize', code: 'BZ'},
  {name: 'Benin', code: 'BJ'},
  {name: 'Bermuda', code: 'BM'},
  {name: 'Bhutan', code: 'BT'},
  {name: 'Bolivia', code: 'BO'},
  {name: 'Bosnia and Herzegovina', code: 'BA'},
  {name: 'Botswana', code: 'BW'},
  {name: 'Bouvet Island', code: 'BV'},
  {name: 'Brazil', code: 'BR'},
  {name: 'British Indian Ocean Territory', code: 'IO'},
  {name: 'Bulgaria', code: 'BG'},
  {name: 'Burkina Faso', code: 'BF'},
  {name: 'Burundi', code: 'BI'},
  {name: 'Cambodia', code: 'KH'},
  {name: 'Cameroon', code: 'CM'},
  {name: 'Canada', code: 'CA'},
  {name: 'Cape Verde', code: 'CV'},
  {name: 'Cayman Islands', code: 'KY'},
  {name: 'Central African Republic', code: 'CF'},
  {name: 'Chad', code: 'TD'},
  {name: 'Chile', code: 'CL'},
  {name: 'China', code: 'CN'},
  {name: 'Christmas Island', code: 'CX'},
  {name: 'Cocos (Keeling) Islands', code: 'CC'},
  {name: 'Colombia', code: 'CO'},
  {name: 'Comoros', code: 'KM'},
  {name: 'Congo (Brazzaville)', code: 'CG'},
  {name: 'Congo (Kinshasa)', code: 'CD'},
  {name: 'Cook Islands', code: 'CK'},
  {name: 'Costa Rica', code: 'CR'},
  {name: 'Cote D\'Ivoire', code: 'CI'},
  {name: 'Croatia', code: 'HR'},
  {name: 'Cuba', code: 'CU'},
  {name: 'Cyprus', code: 'CY'},
  {name: 'Czech Republic', code: 'CZ'},
  {name: 'Denmark', code: 'DK'},
  {name: 'Djibouti', code: 'DJ'},
  {name: 'Dominica', code: 'DM'},
  {name: 'Dominican Republic', code: 'DO'},
  {name: 'Ecuador', code: 'EC'},
  {name: 'Egypt', code: 'EG'},
  {name: 'El Salvador', code: 'SV'},
  {name: 'Equatorial Guinea', code: 'GQ'},
  {name: 'Eritrea', code: 'ER'},
  {name: 'Estonia', code: 'EE'},
  {name: 'Ethiopia', code: 'ET'},
  {name: 'Falkland Islands', code: 'FK'},
  {name: 'Faroe Islands', code: 'FO'},
  {name: 'Fiji', code: 'FJ'},
  {name: 'Finland', code: 'FI'},
  {name: 'France', code: 'FR'},
  {name: 'French Guiana', code: 'GF'},
  {name: 'French Polynesia', code: 'PF'},
  {name: 'French Southern Territories', code: 'TF'},
  {name: 'Gabon', code: 'GA'},
  {name: 'Gambia', code: 'GM'},
  {name: 'Georgia', code: 'GE'},
  {name: 'Germany', code: 'DE'},
  {name: 'Ghana', code: 'GH'},
  {name: 'Gibraltar', code: 'GI'},
  {name: 'Greece', code: 'GR'},
  {name: 'Greenland', code: 'GL'},
  {name: 'Grenada', code: 'GD'},
  {name: 'Guadeloupe', code: 'GP'},
  {name: 'Guam', code: 'GU'},
  {name: 'Guatemala', code: 'GT'},
  {name: 'Guernsey', code: 'GG'},
  {name: 'Guinea', code: 'GN'},
  {name: 'Guinea-Bissau', code: 'GW'},
  {name: 'Guyana', code: 'GY'},
  {name: 'Haiti', code: 'HT'},
  {name: 'Heard Island and Mcdonald Islands', code: 'HM'},
  {name: 'Holy See (Vatican City State)', code: 'VA'},
  {name: 'Honduras', code: 'HN'},
  {name: 'Hong Kong', code: 'HK'},
  {name: 'Hungary', code: 'HU'},
  {name: 'Iceland', code: 'IS'},
  {name: 'India', code: 'IN'},
  {name: 'Indonesia', code: 'ID'},
  {name: 'Iran', code: 'IR'},
  {name: 'Iraq', code: 'IQ'},
  {name: 'Ireland', code: 'IE'},
  {name: 'Isle of Man', code: 'IM'},
  {name: 'Israel', code: 'IL'},
  {name: 'Italy', code: 'IT'},
  {name: 'Jamaica', code: 'JM'},
  {name: 'Japan', code: 'JP'},
  {name: 'Jersey', code: 'JE'},
  {name: 'Jordan', code: 'JO'},
  {name: 'Kazakhstan', code: 'KZ'},
  {name: 'Kenya', code: 'KE'},
  {name: 'Kiribati', code: 'KI'},
  {name: 'North Korea', code: 'KP'},
  {name: 'South Korea', code: 'KR'},
  {name: 'Kuwait', code: 'KW'},
  {name: 'Kyrgyzstan', code: 'KG'},
  {name: 'Laos', code: 'LA'},
  {name: 'Latvia', code: 'LV'},
  {name: 'Lebanon', code: 'LB'},
  {name: 'Lesotho', code: 'LS'},
  {name: 'Liberia', code: 'LR'},
  {name: 'Libyan', code: 'LY'},
  {name: 'Liechtenstein', code: 'LI'},
  {name: 'Lithuania', code: 'LT'},
  {name: 'Luxembourg', code: 'LU'},
  {name: 'Macao', code: 'MO'},
  {name: 'Macedonia', code: 'MK'},
  {name: 'Madagascar', code: 'MG'},
  {name: 'Malawi', code: 'MW'},
  {name: 'Malaysia', code: 'MY'},
  {name: 'Maldives', code: 'MV'},
  {name: 'Mali', code: 'ML'},
  {name: 'Malta', code: 'MT'},
  {name: 'Marshall Islands', code: 'MH'},
  {name: 'Martinique', code: 'MQ'},
  {name: 'Mauritania', code: 'MR'},
  {name: 'Mauritius', code: 'MU'},
  {name: 'Mayotte', code: 'YT'},
  {name: 'Mexico', code: 'MX'},
  {name: 'Micronesia', code: 'FM'},
  {name: 'Moldova', code: 'MD'},
  {name: 'Monaco', code: 'MC'},
  {name: 'Mongolia', code: 'MN'},
  {name: 'Montserrat', code: 'MS'},
  {name: 'Morocco', code: 'MA'},
  {name: 'Mozambique', code: 'MZ'},
  {name: 'Myanmar', code: 'MM'},
  {name: 'Namibia', code: 'NA'},
  {name: 'Nauru', code: 'NR'},
  {name: 'Nepal', code: 'NP'},
  {name: 'Netherlands', code: 'NL'},
  {name: 'Netherlands Antilles', code: 'AN'},
  {name: 'New Caledonia', code: 'NC'},
  {name: 'New Zealand', code: 'NZ'},
  {name: 'Nicaragua', code: 'NI'},
  {name: 'Niger', code: 'NE'},
  {name: 'Nigeria', code: 'NG'},
  {name: 'Niue', code: 'NU'},
  {name: 'Norfolk Island', code: 'NF'},
  {name: 'Northern Mariana Islands', code: 'MP'},
  {name: 'Norway', code: 'NO'},
  {name: 'Oman', code: 'OM'},
  {name: 'Pakistan', code: 'PK'},
  {name: 'Palau', code: 'PW'},
  {name: 'Palestinian', code: 'PS'},
  {name: 'Panama', code: 'PA'},
  {name: 'Papua New Guinea', code: 'PG'},
  {name: 'Paraguay', code: 'PY'},
  {name: 'Peru', code: 'PE'},
  {name: 'Philippines', code: 'PH'},
  {name: 'Pitcairn', code: 'PN'},
  {name: 'Poland', code: 'PL'},
  {name: 'Portugal', code: 'PT'},
  {name: 'Puerto Rico', code: 'PR'},
  {name: 'Qatar', code: 'QA'},
  {name: 'Reunion', code: 'RE'},
  {name: 'Romania', code: 'RO'},
  {name: 'Russian Federation', code: 'RU'},
  {name: 'RWANDA', code: 'RW'},
  {name: 'Saint Helena', code: 'SH'},
  {name: 'Saint Kitts and Nevis', code: 'KN'},
  {name: 'Saint Lucia', code: 'LC'},
  {name: 'Saint Pierre and Miquelon', code: 'PM'},
  {name: 'Saint Vincent and the Grenadines', code: 'VC'},
  {name: 'Samoa', code: 'WS'},
  {name: 'San Marino', code: 'SM'},
  {name: 'Sao Tome and Principe', code: 'ST'},
  {name: 'Saudi Arabia', code: 'SA'},
  {name: 'Senegal', code: 'SN'},
  {name: 'Serbia', code: 'CS'},
  {name: 'Seychelles', code: 'SC'},
  {name: 'Sierra Leone', code: 'SL'},
  {name: 'Singapore', code: 'SG'},
  {name: 'Slovakia', code: 'SK'},
  {name: 'Slovenia', code: 'SI'},
  {name: 'Solomon Islands', code: 'SB'},
  {name: 'Somalia', code: 'SO'},
  {name: 'South Africa', code: 'ZA'},
  {name: 'South Georgia and the South Sandwich Islands', code: 'GS'},
  {name: 'Spain', code: 'ES'},
  {name: 'Sri Lanka', code: 'LK'},
  {name: 'Sudan', code: 'SD'},
  {name: 'Suriname', code: 'SR'},
  {name: 'Svalbard and Jan Mayen', code: 'SJ'},
  {name: 'Swaziland', code: 'SZ'},
  {name: 'Sweden', code: 'SE'},
  {name: 'Switzerland', code: 'CH'},
  {name: 'Syrian Arab Republic', code: 'SY'},
  {name: 'Taiwan', code: 'TW'},
  {name: 'Tajikistan', code: 'TJ'},
  {name: 'Tanzania', code: 'TZ'},
  {name: 'Thailand', code: 'TH'},
  {name: 'Timor-Leste', code: 'TL'},
  {name: 'Togo', code: 'TG'},
  {name: 'Tokelau', code: 'TK'},
  {name: 'Tonga', code: 'TO'},
  {name: 'Trinidad and Tobago', code: 'TT'},
  {name: 'Tunisia', code: 'TN'},
  {name: 'Turkey', code: 'TR'},
  {name: 'Turkmenistan', code: 'TM'},
  {name: 'Turks and Caicos Islands', code: 'TC'},
  {name: 'Tuvalu', code: 'TV'},
  {name: 'Uganda', code: 'UG'},
  {name: 'Ukraine', code: 'UA'},
  {name: 'United Arab Emirates', code: 'AE'},
  {name: 'United Kingdom', code: 'GB'},
  {name: 'United States', code: 'US'},
  {name: 'United States Minor Outlying Islands', code: 'UM'},
  {name: 'Uruguay', code: 'UY'},
  {name: 'Uzbekistan', code: 'UZ'},
  {name: 'Vanuatu', code: 'VU'},
  {name: 'Venezuela', code: 'VE'},
  {name: 'VietNam', code: 'VN'},
  {name: 'Western Sahara', code: 'EH'},
  {name: 'Yemen', code: 'YE'},
  {name: 'Zambia', code: 'ZM'},
  {name: 'Zimbabwe', code: 'ZW'}
]

export const designations = [
  {
    name: 'Anesthesia Assistant',
    id: '3993767000001562003'
  },
  {
    name: 'Behavior Analyst',
    id: '3993767000000111011'
  },
  // This option has been Removed
  // {
  //   name: 'Cape Cod CPR Staff',
  //   id: '3993767000000294047'
  // },
  {
    name: 'Certified Registered Nurse Anesthetist',
    id: '3993767000000111055'
  },
  {
    name: 'Chiropractor',
    id: '3993767000000111079'
  },
  {
    name: 'Dentist',
    id: '3993767000000111031'
  },
  {
    name: 'Dialysis Technician',
    id: '3993767000000111063'
  },
  {
    name: 'Doctor',
    id: '3993767000000111003'
  },
  {
    name: 'Emergency Medical Technician',
    id: '3993767000000111043'
  },
  {
    name: 'Lactation Consultant',
    id: '3993767000000111087'
  },
  {
    name: 'Medical Assistant',
    id: '3993767000000416003',
  },
  {
    name: 'Midwife',
    id: '3993767000000111039'
  },
  {
    name: 'Nurse',
    id: '3993767000000111007'
  },
  {
    name: 'Nursing Assistant',
    id: '3993767000000462009'
  },
  {
    name: 'Nurse Practitioner',
    id: '3993767000000111019'
  },
  {
    name: 'Nutritionist',
    id: '3993767000000111035'
  },
  {
    name: 'Occupational Therapist',
    id: '3993767000000111051'
  },
  {
    name: 'Optometrist',
    id: '3993767000000111067'
  },
  {
    name: 'Paramedic',
    id: '3993767000020111947'
  },
  {
    name: 'Pharmacist',
    id: '3993767000000111047'
  },
  {
    name: 'Phlebotomist',
    id: '3993767000000111059'
  },
  {
    name: 'Physical Therapist',
    id: '3993767000000111083'
  },
  {
    name: 'Physician Assistant',
    id: '3993767000000111015'
  },
  {
    name: 'Podiatrist',
    id: '3993767000000111023'
  },
  {
    name: 'Psychologist',
    id: '3993767000000111071'
  },
  {
    name: 'Radiation Therapist',
    id: '3993767000000111091'
  },
  {
    name: 'Radiologic Technologist',
    id: '3993767000000111095'
  },
  {
    name: 'Respiratory Therapist',
    id: '3993767000000111027'
  },
  {
    name: 'Social Worker',
    id: '3993767000000111075'
  },
  {
    name: 'Speech-Language Pathologist',
    id: '3993767000000136003'
  },
  {
    name: 'Staff',
    id: '3993767000000294013'
  },
  {
    name: 'Student',
    id: '3993767000000441009'
  },
];

export const designationsObject = {
  'Anesthesia Assistant': {
    name: 'Anesthesia Assistant',
    id: '3993767000001562003'
  },
  'Behavior Analyst':{
    name: 'Behavior Analyst',
    id: '3993767000000111011'
  },
  // This option has been Removed
  // 'Cape Cod CPR Staff':{
  //   name: 'Cape Cod CPR Staff',
  //   id: '3993767000000294047'
  // },
  'Certified Registered Nurse Anesthetist':{
    name: 'Certified Registered Nurse Anesthetist',
    id: '3993767000000111055'
  },
  'Chiropractor':{
    name: 'Chiropractor',
    id: '3993767000000111079'
  },
  'Dentist':{
    name: 'Dentist',
    id: '3993767000000111031'
  },
  'Dialysis Technician':{
    name: 'Dialysis Technician',
    id: '3993767000000111063'
  },
  'Doctor':{
    name: 'Doctor',
    id: '3993767000000111003'
  },
  'Emergency Medical Technician':{
    name: 'Emergency Medical Technician',
    id: '3993767000000111043'
  },
  'Lactation Consultant':{
    name: 'Lactation Consultant',
    id: '3993767000000111087'
  },
  'Medical Assistant':{
    name: 'Medical Assistant',
    id: '3993767000000416003',

  },
  'Midwife':{
    name: 'Midwife',
    id: '3993767000000111039'
  },
  'Nurse':{
    name: 'Nurse',
    id: '3993767000000111007'
  },
  'Nursing Assistant':{
    name: 'Nursing Assistant',
    id: '3993767000000462009'
  },
  'Nurse Practitioner':{
    name: 'Nurse Practitioner',
    id: '3993767000000111019'
  },
  'Nutritionist':{
    name: 'Nutritionist',
    id: '3993767000000111035'
  },
  'Occupational Therapist':{
    name: 'Occupational Therapist',
    id: '3993767000000111051'
  },
  'Optometrist':{
    name: 'Optometrist',
    id: '3993767000000111067'
  },
  'Pharmacist':{
    name: 'Pharmacist',
    id: '3993767000000111047'
  },
  'Phlebotomist':{
    name: 'Phlebotomist',
    id: '3993767000000111059'
  },
  'Physical Therapist':{
    name: 'Physical Therapist',
    id: '3993767000000111083'
  },
  'Physician Assistant':{
    name: 'Physician Assistant',
    id: '3993767000000111015'
  },
  'Podiatrist':{
    name: 'Podiatrist',
    id: '3993767000000111023'
  },
  'Psychologist':{
    name: 'Psychologist',
    id: '3993767000000111071'
  },
  'Radiation Therapist':{
    name: 'Radiation Therapist',
    id: '3993767000000111091'
  },
  'Radiologic Technologist':{
    name: 'Radiologic Technologist',
    id: '3993767000000111095'
  },
  'Respiratory Therapist':{
    name: 'Respiratory Therapist',
    id: '3993767000000111027'
  },
  'Social Worker':{
    name: 'Social Worker',
    id: '3993767000000111075'
  },
  'Speech-Language Pathologist':{
    name: 'Speech-Language Pathologist',
    id: '3993767000000136003'
  },
  'Staff':{
    name: 'Staff',
    id: '3993767000000294013'
  },
  'Student':{
    name: 'Student',
    id: '3993767000000441009'
  },
}

export const specialityOptions = ['Allergy and Immunology', 'Anesthesiology', 'Cardiology, Echocardiography', 'Cardiology, Electrophysiology', 'Cardiology, General', 'Cardiology, Interventional', 'Cardiology, Nuclear', 'Critical Care/Intensive Care', 'Dermatology', 'Dermatology, Cosmetic', 'Diabetes', 'Diabetes Educator', 'Emergency Medicine', 'Endocrinology', 'Endocrinology, Reproductive/Infertility', 'Family Medicine', 'Gastroenterology', 'General Practice', 'Genomic Medicine', 'Geriatrics', 'Hematology', 'Hepatology', 'HIV/AIDS', 'Hospice and Palliative Medicine', 'Hospital Medicine', 'Infectious Disease', 'Internal Medicine', 'Neonatal/Perinatal Medicine', 'Nephrology', 'Neurology', 'Neurosurgery', 'Nuclear Medicine', 'Nutrition', 'Obstetrics and Gynecology', 'Oncology, Hematology/Oncology', 'Oncology', 'Oncology, Other', 'Oncology, Radiation', 'Ophthalmology', 'Orthopaedic Surgery', 'Otolaryngology', 'Pain Management', 'Pathology', 'Pediatrics, Allergy', 'Pediatrics, Cardiology', 'Pediatrics', 'Pediatrics, Oncology', 'Physical Medicine and Rehabilitation', 'Plastic Surgery', 'Preventive Medicine', 'Psychiatry', 'Public/Community Health', 'Pulmonary','Podiatry', 'Radiology, Diagnostic', 'Radiology, Interventional', 'Rheumatology', 'Thoracic Surgery',  'Surgery, Oral and Maxillofacial', 'Surgery, General', 'Surgery, Surgical Oncology', 'Surgery, Vascular', 'Transplantation', 'Urology', 'Vascular Surgery', 'Other Clinical', 'Non-Clinical'];
export const showSpecialtyFor = ['3993767000000111003', '3993767000000111007', '3993767000000111019', '3993767000000111015'];

export const studentProgramOptions = ['Chiropractic', 'Dentistry', 'Medicine', 'Nursing', 'Optometry', 'Pharmacy', 'Physical Therapy', 'Physician Assistant', 'Podiatry', 'Other'];
export const showProgramFor = ['3993767000000441009']; // Student only yet

export const states = [
  {
    name: 'Alabama',
    id: '3993767000000047427',
    certificates: '[{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"QACSC (PA/CRNP)","id":"3993767000000061007","validity":"Expires"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"ACSC ","id":"3993767000000061003","validity":"Expires"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"}]'
  },
  {
    name: 'Alaska',
    id: '3993767000000047115',
    certificates: '[{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"PGY-2","id":"3993767000000186655","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"}]'
  },
  {
    name: 'Arizona',
    id: '3993767000000047127',
    certificates: '[{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"}]'
  },
  {
    name: 'Arkansas',
    id: '3993767000000047139',
    certificates: '[{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"Arkansas Jurisprudence Exam","id":"3993767000000191215","validity":"Lifetime"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"License","id":"3993767000000026009","validity":"Expires"}]'
  },
  {
    name: 'California',
    id: '3993767000000047151',
    certificates: '[{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"PGY-1","id":"3993767000000186663","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"California Portfolio Exam","id":"3993767000000186667","validity":"Lifetime"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"California Practice Standards","id":"3993767000000191231","validity":"Lifetime"},{"name":"Jurisprudence Exam ","id":"3993767000000191239","validity":"Lifetime"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"}]'
  },
  {
    name: 'Colorado',
    id: '3993767000000047163',
    certificates: '[{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"OSCE","id":"3993767000000186787","validity":"Lifetime"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"PGY-1","id":"3993767000000186663","validity":"Lifetime"},{"name":"Portfolio Exam","id":"3993767000000186803","validity":"Lifetime"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"}]'
  },
  {
    name: 'Connecticut',
    id: '3993767000000026017',
    certificates: '[{"name":"PGY-1","id":"3993767000000186663","validity":"Lifetime"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CSR","id":"3993767000000047099","validity":"Expires"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"}]'
  },
  {
    name: 'Delaware',
    id: '3993767000000047443',
    certificates: '[{"name":"CSR","id":"3993767000000047099","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"Delaware Practical Board Examination","id":"3993767000000186843","validity":"Lifetime"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"License","id":"3993767000000026009","validity":"Expires"}]'
  },
  {
    name: 'Certificate Courses',
    id: '3993767000000047787',
    certificates: '[{"name":"Handtevy Instructor","id":"3993767000000315019","validity":"Expires"},{"name":"DAC Instructor","id":"3993767000000315023","validity":"Expires"},{"name":"CPR","id":"3993767000000191723","validity":"Expires"},{"name":"EMT","id":"3993767000000315027","validity":"Expires"},{"name":"Paramedic","id":"3993767000000315031","validity":"Expires"},{"name":"Registered Nurse","id":"3993767000000315035","validity":"Expires"},{"name":"ACLS","id":"3993767000000047791","validity":"Expires"},{"name":"PALS","id":"3993767000000047799","validity":"Expires"},{"name":"BLS","id":"3993767000000047807","validity":"Expires"},{"name":"NRP","id":"3993767000000047815","validity":"Expires"},{"name":"ATLS","id":"3993767000000047823","validity":"Expires"},{"name":"ACLS Instructor","id":"3993767000000315003","validity":"Expires"},{"name":"PALS Instructor","id":"3993767000000315007","validity":"Expires"},{"name":"BLS Instructor","id":"3993767000000315011","validity":"Expires"},{"name":"ECSI Instructor","id":"3993767000000315015","validity":"Expires"}]'
  },
  {
    name: 'Florida',
    id: '3993767000000047175',
    certificates: '[{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"}]'
  },
  {
    name: 'Georgia',
    id: '3993767000000047187',
    certificates: '[{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"Georgia Practical Exam ","id":"3993767000000191323","validity":"Lifetime"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"}]'
  },
  {
    name: 'Hawaii',
    id: '3993767000000047475',
    certificates: '[{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"TOFEL","id":"3993767000000191335","validity":"Lifetime"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"TSE","id":"3993767000000191343","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"NED Registration","id":"3993767000000061031","validity":"Expires"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"}]'
  },
  {
    name: 'Health',
    id: '3993767000000075003',
    certificates: '[{"name":"COVID-19 Vaccine","id":"3993767000000818003","validity":"Expires"},{"name":"Drug Screen","id":"3993767000000818007","validity":"Expires"},{"name":"Hepatitis B Vaccine","id":"3993767000000818011","validity":"Expires"},{"name":"Influenza Vaccination","id":"3993767000000075011","validity":"Expires"},{"name":"Measles, Mumps & Rubella Vaccine","id":"3993767000000818015","validity":"Expires"},{"name":"PPD","id":"3993767000000623013","validity":"Expires"},{"name":"Tdap Vaccine","id":"3993767000000818023","validity":"Expires"},{"name":"Varicella Vaccine","id":"3993767000000818027","validity":"Expires"}]'
  },
  {
    name: 'Idaho',
    id: '3993767000000047723',
    certificates: '[{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"TOFEL","id":"3993767000000191335","validity":"Lifetime"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"TSE","id":"3993767000000191343","validity":"Lifetime"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"CSR","id":"3993767000000047099","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"}]'
  },
  {
    name: 'Illinois',
    id: '3993767000000047491',
    certificates: '[{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"CSR","id":"3993767000000047099","validity":"Expires"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"}]'
  },
  {
    name: 'Indiana',
    id: '3993767000000047507',
    certificates: '[{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"CSR","id":"3993767000000047099","validity":"Expires"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"}]'
  },
  {
    name: 'Iowa',
    id: '3993767000000047523',
    certificates: '[{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"California Portfolio Exam","id":"3993767000000186667","validity":"Lifetime"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"DLOSE","id":"3993767000000186671","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"CSR","id":"3993767000000047099","validity":"Expires"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"}]'
  },
  {
    name: 'Kansas',
    id: '3993767000000047199',
    certificates: '[{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"}]'
  },
  {
    name: 'Kentucky',
    id: '3993767000000047211',
    certificates: '[{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"}]'
  },
  {
    name: 'Louisiana',
    id: '3993767000000047539',
    certificates: '[{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"CDS","id":"3993767000000061015","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"}]'
  },
  {
    name: 'Maine',
    id: '3993767000000026029',
    certificates: '[{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"}]'
  },
  {
    name: 'Maryland',
    id: '3993767000000047555',
    certificates: '[{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"CDS","id":"3993767000000061015","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"}]'
  },
  {
    name: 'Massachusetts',
    id: '3993767000000019003',
    certificates: '[{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CDCA DSE","id":"3993767000000188047","validity":"Lifetime"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"Massachusetts CSR","id":"3993767000000019031","validity":"Expires"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"XDEA","id":"3993767000000019039","validity":"Expires"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"}]'
  },
  {
    name: 'Michigan',
    id: '3993767000000047571',
    certificates: '[{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"Controlled Substance License","id":"3993767000000061035","validity":"Expires"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"OSCE","id":"3993767000000186787","validity":"Lifetime"}]'
  },
  {
    name: 'Minnesota',
    id: '3993767000000047235',
    certificates: '[{"name":"PGY-1","id":"3993767000000186663","validity":"Lifetime"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"OSCE","id":"3993767000000186787","validity":"Lifetime"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"}]'
  },
  {
    name: 'Mississippi',
    id: '3993767000000047247',
    certificates: '[{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"}]'
  },
  {
    name: 'Missouri',
    id: '3993767000000047591',
    certificates: '[{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"BNDD Practitioner","id":"3993767000000061019","validity":"Expires"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"BNDD Mid-Level","id":"3993767000000061023","validity":"Expires"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"}]'
  },
  {
    name: 'Montana',
    id: '3993767000000047259',
    certificates: '[{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"}]'
  },
  {
    name: 'Nebraska',
    id: '3993767000000047271',
    certificates: '[{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"}]'
  },
  {
    name: 'Nevada',
    id: '3993767000000047743',
    certificates: '[{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"CSR","id":"3993767000000047099","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"}]'
  },
  {
    name: 'New Hampshire',
    id: '3993767000000020003',
    certificates: '[{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CSR","id":"3993767000000047099","validity":"Expires"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"}]'
  },
  {
    name: 'New Mexico',
    id: '3993767000000047615',
    certificates: '[{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CSR","id":"3993767000000047099","validity":"Expires"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"}]'
  },
  {
    name: 'New York',
    id: '3993767000000026021',
    certificates: '[{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"Assessment Training ","id":"3993767000000192011","validity":"Lifetime"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"PGY-1","id":"3993767000000186663","validity":"Lifetime"},{"name":"Board Certified Behavior Analyst Certification ","id":"3993767000000200003","validity":"Expires"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"Child Abuse Identification and Reporting Certificate ","id":"3993767000000200011","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"HIPAA Awareness Training certificate ","id":"3993767000000200019","validity":"Expires"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"Sexual Harassment Training Certificate ","id":"3993767000000200027","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"Restraint Training ","id":"3993767000000200035","validity":"Expires"},{"name":"Teaching Certification ","id":"3993767000000200043","validity":"Lifetime"},{"name":"BCBA/BCaBA Supervision Certification ","id":"3993767000000200051","validity":"Lifetime"}]'
  },
  {
    name: 'New Jersey',
    id: '3993767000000026033',
    certificates: '[{"validity":"Expires","id":"3993767000000047099","name":"CSR"},{"validity":"Expires","id":"3993767000000019023","name":"DEA"},{"validity":"Expires","id":"3993767000000026009","name":"License"},{"validity":"Expires","id":"3993767000000186019","name":"NBRC"},{"validity":"Expires","id":"3993767000000186003","name":"RRT"},{"validity":"Expires","id":"3993767000000186011","name":"CRT"},{"validity":"Lifetime","id":"3993767000000186639","name":"CDCA"},{"validity":"Lifetime","id":"3993767000000186643","name":"CITA"},{"validity":"Lifetime","id":"3993767000000191159","name":"FPGEC"},{"validity":"Lifetime","id":"3993767000000191151","name":"NAPLEX"},{"validity":"Lifetime","id":"3993767000000191155","name":"MPJE"}]'
  },
  {
    name: 'North Carolina',
    id: '3993767000000047283',
    certificates: '[{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"}]'
  },
  {
    name: 'North Dakota',
    id: '3993767000000047311',
    certificates: '[{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"}]'
  },
  {
    name: 'Ohio',
    id: '3993767000000047095',
    certificates: '[{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"TSE","id":"3993767000000191343","validity":"Lifetime"},{"name":"PGY-1","id":"3993767000000186663","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"TOFEL","id":"3993767000000191335","validity":"Lifetime"}]'
  },
  {
    name: 'Oklahoma',
    id: '3993767000000047631',
    certificates: '[{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"OBNDD Registration ","id":"3993767000000061039","validity":"Expires"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"}]'
  },
  {
    name: 'Oregon',
    id: '3993767000000047327',
    certificates: '[{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"DCLOSE","id":"3993767000000188371","validity":"Lifetime"}]'
  },
  {
    name: 'Pennsylvania',
    id: '3993767000000047339',
    certificates: '[{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"}]'
  },
  {
    name: 'Rhode Island',
    id: '3993767000000047647',
    certificates: '[{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"CSR","id":"3993767000000047099","validity":"Expires"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"}]'
  },
  {
    name: 'South Carolina',
    id: '3993767000000047671',
    certificates: '[{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"CSR","id":"3993767000000047099","validity":"Expires"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"}]'
  },
  {
    name: 'South Dakota',
    id: '3993767000000047759',
    certificates: '[{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"Jurisprudence Exam ","id":"3993767000000191239","validity":"Lifetime"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"SD CSR","id":"3993767000000061043","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"}]'
  },
  {
    name: 'Tennessee',
    id: '3993767000000047351',
    certificates: '[{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"License","id":"3993767000000026009","validity":"Expires"}]'
  },
  {
    name: 'Texas',
    id: '3993767000000047363',
    certificates: '[{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"}]'
  },
  {
    name: 'Training',
    id: '3993767000000075007',
    certificates: '[{"name":"Medical School Diploma","id":"3993767000000075019","validity":"Lifetime"}]'
  },
  {
    name: 'Utah',
    id: '3993767000000047687',
    certificates: '[{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"CSR","id":"3993767000000047099","validity":"Expires"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"}]'
  },
  {
    name: 'Vermont',
    id: '3993767000000026025',
    certificates: '[{"name":"FPGEE","id":"3993767000000191795","validity":"Lifetime"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"TOFEL","id":"3993767000000191335","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"TSE","id":"3993767000000191343","validity":"Lifetime"}]'
  },
  {
    name: 'Virginia',
    id: '3993767000000047379',
    certificates: '[{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"VA Law Exam","id":"3993767000000191823","validity":"Lifetime"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"}]'
  },
  {
    name: 'Washington',
    id: '3993767000000047391',
    certificates: '[{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"OSCE","id":"3993767000000186787","validity":"Lifetime"},{"name":"PGY-1","id":"3993767000000186663","validity":"Lifetime"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"DLOSE","id":"3993767000000186671","validity":"Lifetime"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"}]'
  },
  {
    name: 'Washington DC',
    id: '3993767000000047459',
    certificates: '[{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"CSR","id":"3993767000000047099","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"}]'
  },
  {
    name: 'West Virginia',
    id: '3993767000000047403',
    certificates: '[{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"WV Errors and Omissions Exam","id":"3993767000000191855","validity":"Lifetime"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"}]'
  },
  {
    name: 'Wisconsin',
    id: '3993767000000047415',
    certificates: '[{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"}]'
  },
  {
    name: 'Transcripts and Scores',
    id: '3993767000000078039',
    certificates: '[{"name":"Transcripts & Scores","id":"3993767000000566023","validity":"Expires"}]'
  },
  {
    name: 'Malpractice',
    id: '3993767000000078035',
    certificates: '[{"name":"Malpractice","id":"3993767000000461019","validity":"Expires"}]'
  }
];

export const allCertificates = [
  {
    name: 'ACLS',
    id: '3993767000000047791'
  },
  {
    name: 'ACLS Instructor',
    id: '3993767000000315003'
  },
  {
    name: 'ACSC ',
    id: '3993767000000061003'
  },
  {
    name: 'Arkansas Jurisprudence Exam',
    id: '3993767000000191215'
  },
  {
    name: 'ATLS',
    id: '3993767000000047823'
  },
  {
    name: 'BLS',
    id: '3993767000000047807'
  },
  {
    name: 'BLS Instructor',
    id: '3993767000000315011'
  },
  {
    name: 'California Portfolio Exam',
    id: '3993767000000186667'
  },
  {
    name: 'California Practice Standards',
    id: '3993767000000191231'
  },
  {
    name: 'CDCA',
    id: '3993767000000186639'
  },
  {
    name: 'CITA',
    id: '3993767000000186643'
  },
  {
    name: 'CPR',
    id: '3993767000000191723'
  },
  {
    name: 'CRDTS',
    id: '3993767000000186647'
  },
  {
    name: 'CRT',
    id: '3993767000000186011'
  },
  {
    name: 'CSR',
    id: '3993767000000047099'
  },
  {
    name: 'DAC Instructor',
    id: '3993767000000315023'
  },
  {
    name: 'DEA',
    id: '3993767000000019023'
  },
  {
    name: 'Delaware Practical Board Examination',
    id: '3993767000000186843'
  },
  {
    name: 'ECSI Instructor',
    id: '3993767000000315015'
  },
  {
    name: 'EMT',
    id: '3993767000000315027'
  },
  {
    name: 'FPGEC',
    id: '3993767000000191159'
  },
  {
    name: 'Georgia Practical Exam ',
    id: '3993767000000191323'
  },
  {
    name: 'Handtevy Instructor',
    id: '3993767000000315019'
  },
  {
    name: 'Influenza Vaccination',
    id: '3993767000000075011'
  },
  {
    name: 'Jurisprudence Exam ',
    id: '3993767000000191239'
  },
  {
    name: 'License',
    id: '3993767000000026009'
  },
  {
    name: 'MPJE',
    id: '3993767000000191155'
  },
  {
    name: 'NAPLEX',
    id: '3993767000000191151'
  },
  {
    name: 'NBRC',
    id: '3993767000000186019'
  },
  {
    name: 'NED Registration',
    id: '3993767000000061031'
  },
  {
    name: 'NRP',
    id: '3993767000000047815'
  },
  {
    name: 'OSCE',
    id: '3993767000000186787'
  },
  {
    name: 'PALS',
    id: '3993767000000047799'
  },
  {
    name: 'PALS Instructor',
    id: '3993767000000315007'
  },
  {
    name: 'Paramedic',
    id: '3993767000000315031'
  },
  {
    name: 'PGY-1',
    id: '3993767000000186663'
  },
  {
    name: 'PGY-2',
    id: '3993767000000186655'
  },
  {
    name: 'Portfolio Exam',
    id: '3993767000000186803'
  },
  {
    name: 'QACSC (PA/CRNP)',
    id: '3993767000000061007'
  },
  {
    name: 'Registered Nurse',
    id: '3993767000000315035'
  },
  {
    name: 'RRT',
    id: '3993767000000186003'
  },
  {
    name: 'SRTA',
    id: '3993767000000186659'
  },
  {
    name: 'TOFEL',
    id: '3993767000000191335'
  },
  {
    name: 'TSE',
    id: '3993767000000191343'
  },
  {
    name: 'WREB',
    id: '3993767000000186651'
  }
];

export const certificatesDictionary = {
  full: {
    text: '150+',
    color: '#5d983b',
    image: 'greenfull.png',
  },
  fullQuarter: {
    text: '120+',
    color: '#4a9767',
    image: 'greenquarters.png',
  },
  goingToExpire: {
    text: '90+',
    color: '#f69243',
    image: 'goingToExpire.png',
  },
  low: {
    text: '60+',
    color: '#d0a521',
    image: 'low.png',
  },
  lowest: {
    text: '30+',
    color: '#FF0000',
    image: 'lowest.png',
  },
  default: {
    text: '',
    color: 'rgba(26, 92, 234, 0.68)',
    image: 'default.png',
  },
  initiated: {
    text: '',
    color: 'rgba(26, 92, 234, 0.68)',
    image: 'initiated.svg',
  },
  application_submitted: {
    text: '',
    color: 'rgba(26, 92, 234, 0.68)',
    image: 'application_submitted.svg',
  },
  verifications: {
    text: '',
    color: 'rgba(26, 92, 234, 0.68)',
    image: 'verifications.svg',
  },
  application_complete: {
    text: '',
    color: 'rgba(26, 92, 234, 0.68)',
    image: 'application_complete.svg',
  },
};
export const courseDetails = [
  {
    name: 'acls-course',
    full_name: 'Advanced Cardiovascular Life Support',
    short_name: 'ACLS',
    id: 4526,
    time_in_minutes: 600,
    cme_credits: 4.25,
    google_event: 'ACLS Start Now Button',
  },
  {
    name: 'bls-course',
    full_name: 'Basic Life Support',
    short_name: 'BLS',
    id: 9985,
    time_in_minutes: 600,
    cme_credits: 3,
    google_event: 'BLS Start Now Button',
  },
  {
    name: 'pals-course',
    full_name: 'Pediatric Advanced Life Support',
    short_name: 'PALS',
    id: 9238,
    time_in_minutes: 720,
    cme_credits: 4,
    google_event: 'PALS Start Now Button',
  }
];

export const certCategories = [
  {
    name: 'Certificate Courses',
    id: '3993767000000047787',
    description: 'e.g ACLS, BLS, PALS'
  },
  {
    name: 'CME/CE',
    id: 'cme',
    description: 'e.g Pain Management, Opioid Best Practices'
  },
  {
    name: 'Health',
    id: '3993767000000075003',
    description: 'e.g Influenza/COVID-19 Vaccine'
  },
  {
    name: 'Malpractice',
    id: '3993767000000078035'
  },
  {
    name: 'Regulatory',
    id: 'show_states',
    description: 'e.g State Licenses'
  },
  {
    name: 'Transcripts and Scores',
    id: '3993767000000078039'
  }
];

export const creditTypes = [
  {
    name: 'AMA PRA Category 1',
    id: '3993767000000230065'
  },
];

export const specificDesignations = [
  {
    name: "Select Specific Designation",
    id: "3993767000000752003"
  },
  {
    name: "Antimicrobial Stewardship",
    id: "3993767000000752079"
  },
  {
    name: "Bioterrorism",
    id: "3993767000000752075"
  },
  {
    name: "Suicide Prevention",
    id: "3993767000000752071"
  },
  {
    name: "Treatment of Patients with Cognitive Impairments",
    id: "3993767000000752067"
  },
  {
    name: "Behavior Health",
    id: "3993767000000752063"
  },
  {
    name: "Cultural Competency",
    id: "3993767000000752059"
  },
  {
    name: "Human Trafficking",
    id: "3993767000000752055"
  },
  {
    name: "Sexual and Physical Abuse",
    id: "3993767000000752051"
  },
  {
    name: "Domestic Violence",
    id: "3993767000000752047"
  },
  {
    name: "Sexual Assault",
    id: "3993767000000752043"
  },
  {
    name: "Pharmacology",
    id: "3993767000000752039"
  },
  {
    name: "HIV/AIDS",
    id: "3993767000000752035"
  },
  {
    name: "Infection Control",
    id: "3993767000000752031"
  },
  {
    name: "Infectious Disease",
    id: "3993767000000752027"
  },
  {
    name: "Medical Error Prevention",
    id: "3993767000000752023"
  },
  {
    name: "Record Keeping",
    id: "3993767000000752019"
  },
  {
    name: "Board Regulations",
    id: "3993767000000752015"
  },
  {
    name: "Scope of Practice",
    id: "3993767000000752011"
  },
  {
    name: "Medical Ethics",
    id: "3993767000000752007"
  },
  {
    name: "End of Life Care",
    id: "3993767000000230081"
  },
  {
    name: "Risk Management",
    id: "3993767000000230077"
  },
  {
    name: "Opioid Education and Pain Management",
    id: "3993767000000230069"
  }
];

export const staticCMECourses = [
  {
    name: 'Opioid Best Practices',
    slug: 'skeleton',
    isStatic: true,
    cme_credits: 1,
    url: 'http://pafp.com/Pages/Content.aspx?id=1698',
    filterKeyword: 'opioid'
  },
  {
    name: 'Opioid Prescriber Training Program: 1',
    slug: 'CME in-app graphics-03',
    isStatic: true,
    cme_credits: 2,
    url: "http://pharmacy.buffalo.edu/academics/continuing-education/events/opioid-prescriber-training-program-part-11.html",
    filterKeyword: 'pain-management'
  },
  {
    name: 'Opioid Prescriber Training Program: 2',
    slug: 'CME part 2',
    isStatic: true,
    cme_credits: 2,
    url: 'http://pharmacy.buffalo.edu/academics/continuing-education/events/opioid-prescriber-training-program-part-21.html',
    filterKeyword: 'pain-management'
  },
  {
    name: 'Non-Pharmaceutical Options for Chronic Pain',
    slug: 'CME in-app graphics-02',
    isStatic: true,
    cme_credits: 1,
    url: 'https://www.pafp.com/Pages/Content.aspx?id=1704',
    filterKeyword: 'pain-management'
  },
  {
    name: 'COVID 19: Maternity Care',
    slug: 'Maternity',
    isStatic: true,
    cme_credits: 1,
    url: 'https://lms.aafp.org/node/42022?#group-tabs-node-course-default1',
    filterKeyword: 'covid'
  },
  {
    name: 'COVID-19: Providing Comprehensive Pediatric Care During COVID-19',
    slug: 'Pediatric',
    isStatic: true,
    cme_credits: 1,
    url: 'https://lms.aafp.org/node/42104?#group-tabs-node-course-default1',
    filterKeyword: 'covid'
  },
  {
    name: 'COVID-19 Applied Cases: Symptomatic And Asymptomatic Patients',
    slug: 'Patients',
    isStatic: true,
    cme_credits: 1.25,
    url: 'https://lms.aafp.org/node/42010?#group-tabs-node-course-default1',
    filterKeyword: 'covid'
  },
  {
    name: 'COVID-19 Applied Cases: Mild, Moderate, And Severe Symptoms',
    slug: 'Symptoms',
    isStatic: true,
    cme_credits: 1,
    url: 'https://lms.aafp.org/node/42006?#group-tabs-node-course-default1',
    filterKeyword: 'covid'
  },
  {
    name: 'COVID-19: Virus Recovery And Treatment',
    slug: 'Virus Recovery',
    isStatic: true,
    cme_credits: 1,
    url: 'https://lms.aafp.org/node/42080?#group-tabs-node-course-default1',
    filterKeyword: 'covid'
  },
  {
    name: 'COVID-19 Testing: Fundamental And Application To Practise',
    slug: 'Testing',
    isStatic: true,
    cme_credits: 1,
    url: 'https://lms.aafp.org/node/42076?#group-tabs-node-course-default1',
    filterKeyword: 'covid'
  },
  {
    name: 'Managing The COVID-19 Crisis: Maintaining The Emotional Health Of You, Your Team, And Your Patients',
    slug: 'Managing',
    isStatic: true,
    cme_credits: 1,
    url: 'https://lms.aafp.org/node/42034?#group-tabs-node-course-default1',
    filterKeyword: 'covid'
  },
  {
    name: 'Antimicrobial Stewardship: Improving Clinical Outcomes by Optimization of Antibiotic Practices',
    slug: 'image-1',
    isStatic: true,
    cme_credits: 1,
    url: 'https://stanford.cloud-cme.com/course/courseoverview?P=0&EID=20868',
    filterKeyword: 'antimicrobial-stewardship'
  },
  {
    name: 'Antimicrobial Stewardship: To Prescribe or Not To Prescribe? Antibiotics and Outpatient Infections',
    slug: 'image-2',
    isStatic: true,
    cme_credits: 1,
    url: 'https://stanford.cloud-cme.com/course/courseoverview?P=0&EID=20758',
    filterKeyword: 'antimicrobial-stewardship'
  },
  {
    name: 'Behavior Health: Identifying Early Signs of Psychosis in Adolescents and Young Adults',
    slug: 'image-3',
    isStatic: true,
    cme_credits: 1,
    url: 'https://stanford.cloud-cme.com/course/courseoverview?P=0&EID=20951',
    filterKeyword: 'behavior-health'
  },
  {
    name: 'Culture Competency: Health Across the Gender Spectrum',
    slug: 'image-5',
    isStatic: true,
    cme_credits: 1,
    url: 'https://stanford.cloud-cme.com/course/courseoverview?P=0&EID=20959',
    filterKeyword: 'culture-competency'
  },
  {
    name: 'Medical Error Prevention: CSI- ME Case Studies In Medical Errors',
    slug: 'image-6',
    isStatic: true,
    cme_credits: 1,
    url: 'https://stanford.cloud-cme.com/course/courseoverview?P=0&EID=23777',
    filterKeyword: 'medical-error-prevention'
  },
  {
    name: 'Medical Error Prevention: Medical Errors',
    slug: 'image-10',
    isStatic: true,
    cme_credits: 1,
    url: 'https://cpd.education.bcm.edu/content/module-2-medical-errors',
    filterKeyword: 'medical-error-prevention, medical-ethics'
  },
  {
    name: 'Medical Ethics: Clinical Decision Making',
    slug: 'image-8',
    isStatic: true,
    cme_credits: 1,
    url: 'https://cpd.education.bcm.edu/content/module-7-clinical-decision-making',
    filterKeyword: 'record-keeping, medical-ethics'
  },
  {
    name: 'Cultural Competency: Social Determinants of Health and Cultural Competency',
    slug: 'image-4',
    isStatic: true,
    cme_credits: 1,
    url: 'https://cpd.education.bcm.edu/content/module-1-social-determinants-health-and-cultural-competency',
    filterKeyword: 'medical-ethics, culture-competency'
  },
  {
    name: 'Medical Ethics: Conflicts of Interest',
    slug: 'image-11',
    isStatic: true,
    cme_credits: 1,
    url: 'https://cpd.education.bcm.edu/content/module-6-conflicts-interest',
    filterKeyword: 'medical-ethics'
  },
  {
    name: 'Medical Ethics: Effective Communication and Conflict Management',
    slug: 'image-12',
    isStatic: true,
    cme_credits: 1,
    url: 'https://cpd.education.bcm.edu/content/module-3-effective-communication-and-conflict-management',
    filterKeyword: 'medical-ethics'
  },
  {
    name: 'Medical Ethics: Ethical Problem Solving',
    slug: 'image-13',
    isStatic: true,
    cme_credits: 1,
    url: 'https://cpd.education.bcm.edu/content/module-4-ethical-problem-solving',
    filterKeyword: 'medical-ethics'
  },
  {
    name: 'Medical Ethics: Healthcare Economics',
    slug: 'image-14',
    isStatic: true,
    cme_credits: 1,
    url: 'https://cpd.education.bcm.edu/content/module-8-healthcare-economics',
    filterKeyword: 'medical-ethics'
  },
  {
    name: 'Medical Ethics: Impaired Physicians',
    slug: 'image-9',
    isStatic: true,
    cme_credits: 1,
    url: 'https://cpd.education.bcm.edu/content/module-10-impaired-physicians',
    filterKeyword: 'medical-ethics, risk-management'
  },
  {
    name: 'Medical Ethics: Pediatrics Ethics',
    slug: 'image-15',
    isStatic: true,
    cme_credits: 1,
    url: 'https://cpd.education.bcm.edu/content/module-9-pediatrics-ethics',
    filterKeyword: 'medical-ethics'
  },
  {
    name: 'Medical Ethics: Professionalism in Practice',
    slug: 'image-16',
    isStatic: true,
    cme_credits: 1,
    url: 'https://cpd.education.bcm.edu/content/module-5-professionalism-practice',
    filterKeyword: 'medical-ethics'
  }
]

export const cmeCoursesFilter = [
  {
    name: 'Antimicrobial Stewardship',
    filterKeyword: 'antimicrobial-stewardship'
  },
  {
    name: 'Behavior Health',
    filterKeyword: 'behavior-health'
  },
  {
    name: 'Covid-19',
    filterKeyword: 'covid'
  },
  {
    name: 'Culture Competency',
    filterKeyword: 'culture-competency'
  },
  {
    name: 'Medical Error Prevention',
    filterKeyword: 'medical-error-prevention'
  },
  {
    name: 'Medical Ethics',
    filterKeyword: 'medical-ethics'
  },
  {
    name: 'Opioid Education',
    filterKeyword: 'opioid'
  },
  {
    name: 'Pain Management',
    filterKeyword: 'pain-management'
  },
  {
    name: 'Record Keeping',
    filterKeyword: 'record-keeping'
  },
  {
    name: 'Risk Management',
    filterKeyword: 'risk-management'
  },


]

export const staticShortCourses = [
  {
    name: 'A case of progressive headache and blurring of vision',
    slug: 'headache',
    isStatic: true,
    url: 'https://medtigo.com/quiz/a-case-of-progressive-headache-and-blurring-of-vision/',
  },
  {
    name: 'A man with sudden chest pain and hypertension',
    slug: 'hypertension',
    isStatic: true,
    url: ' https://medtigo.com/quiz/a-man-with-sudden-chest-pain-and-hypertension/ ',
  },
  {
    name: 'A young male with features of acute myocardial infarction',
    slug: 'myocardial',
    isStatic: true,
    url: '  https://medtigo.com/quiz/a-young-male-with-features-of-acute-myocardial-infarction/ ',
  },
  {
    name: 'A child with sudden hip pain and limp',
    slug: 'hip-pain',
    isStatic: true,
    url: ' https://medtigo.com/quiz/a-child-with-sudden-hip-pain-and-limp/',
  },
  {
    name: 'A young man with unexpected hematuria and right flank pain',
    slug: 'hematuria',
    isStatic: true,
    url: ' https://medtigo.com/quiz/a-young-man-with-unexpected-hematuria-and-right-flank-pain/',
  },
  {
    name: 'A case of syncope',
    slug: 'graph',
    isStatic: true,
    url: ' https://medtigo.com/quiz/a-case-of-syncope/',
  },
  {
    name: 'A case of pain in the knee?',
    slug: 'knee-pain',
    isStatic: true,
    url: ' https://medtigo.com/a-case-of-pain-in-the-knee/',
  },
  {
    name: 'Pediatric vomiting: A red flag?',
    slug: 'vomit',
    isStatic: true,
    url: 'https://medtigo.com/pediatric-vomiting-a-red-flag/',
  },
  {
    name: 'A case of chronic cough',
    slug: 'heart-beat',
    isStatic: true,
    url: 'https://medtigo.com/quiz/a-case-of-chronic-cough/',
  },
  {
    name: 'Losing too much hair',
    slug: 'hair',
    isStatic: true,
    url: 'https://medtigo.com/losing-too-much-hair/',
  },
  {
    name: 'Rash and strawberry tongue in children',
    slug: 'tongue',
    isStatic: true,
    url: 'https://medtigo.com/rash-and-strawberry-tongue-in-children/'
  },
  {
    name: 'A rare case of autoimmune disease',
    slug: 'desc-1',
    isStatic: true,
    url: 'https://medtigo.com/granulomatosis-with-polyangiitis/',
  },
]
