import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import authService from 'src/services/authService';

function LogoutGuard({ component: Component, render, ...rest }) {

  const account = authService.logout();
  return <Redirect to="/login" />;
  
}

LogoutGuard.propTypes = {
  component: PropTypes.any,
  render: PropTypes.func
};

export default LogoutGuard;
