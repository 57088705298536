import React, {
  useEffect,
  useState
} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import SplashScreen from 'src/components/SplashScreen';
import { setUserData, logout } from 'src/actions/accountActions';
import { getNotifications } from 'src/actions/notificationsActions';
import authService from 'src/services/authService';

function Auth({ children }) {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const { user } = useSelector((state) => state.account);

  // useEffect(() => {
  //   if(user && !isLoading){
  //     const botData = document.createElement('script');
  //     botData.type = 'text/javascript';
  //     botData.innerHTML = '$zoho.salesiq.ready=function(){$zoho.salesiq.visitor.name("' + user.first_name + '");$zoho.salesiq.visitor.email("' + user.email + '");}';
  //     document.body.appendChild(botData);
  //     window.setTimeout(() => {
  //       document.querySelector('.zsiq_floatmain') && document.querySelector('.zsiq_floatmain').classList.add('visible');
  //     }, 8000);
  //   }
  // }, [user, isLoading]);

  useEffect(() => {
    const initAuth = async () => {
      // authService.setAxiosInterceptors({
      //   onLogout: () => dispatch(logout())
      // });
      // authService.handleAuthentication();

      // if (authService.isAuthenticated()) {
      //   const user = await authService.loginInWithToken();
      //   await dispatch(setUserData(user));
      //   await dispatch(getNotifications());
      // }

      setLoading(false);
    };

    initAuth();
  }, [dispatch]);

  if (isLoading) {
    return <SplashScreen />;
  }

  return children;
}

Auth.propTypes = {
  children: PropTypes.any
};

export default Auth;
